<template lang='pug'>
  .main-page.page-noScroll.pb-0.position-relative
    v-row#page-head.d-flex.align-center.justify-md-space-between
      v-col.d-flex.align-center.px-0.pb-0.px-md-2.pb-md-4(cols='4' md='6' )
        h1.my-0 {{$t('SEGMENT.TITLE')}}
        v-btn.ml-3.edit-deal-btn(@click='showAddSegDialog()' width='40' height='40' :ripple='false' color='success' depressed dark fab)
          v-icon(size='16') icon-add
      v-col(cols='12' md='6' )
        v-row.justify-md-end(no-gutters='no-gutters')
          v-col(cols='auto')
            .btn-group
              v-btn(@click='onCopy(selectedRows[0])' :disabled='selectedRows.length > 1 || selectedRows.length == 0' width='80' height='38' color='white' depressed tile dark)
                v-icon(size='16') icon-edit
                span {{$t('GENERAL.COPY')}}
              v-btn.border-left(@click='onDelete()' :items="contactData" :disabled="selectedRows.length == 0" width='80' height='38' color='white' depressed tile dark)
                v-icon(size='16') icon-remove
                span {{$t('GENERAL.DELETE')}}
          v-col(cols='auto').ml-4
            .btn-group
              v-text-field(v-model='searchSegment' flat dense solo prepend-inner-icon='icon-search')
    #page-inner.scroll-x.pt-md-6.pb-0.height_100vh
      .data-area
        v-data-table.h-100.deals-table.ellipsis(:headers='segmentsHeader' :header-props="{sortIcon: 'mdi mdi-arrow-up'}" :items='segmentList' :search='searchSegment' :items-per-page='itemPerPage' :page.sync='page' :page-count='pageCount' :sort-desc.sync='sortByDesc' :sort-by.sync="sortBy" multi-sort height='100%' hide-default-footer='hide-default-footer' show-select item-key='id' v-model="selectedRows" :loading='isLoading' loading-text="Loading..." )
          template(v-slot:item.name='{ item }')
            v-btn.w-100.d-block(v-if='!!item.name' :href='"Segment/" + item.id' color='primary' text :title="item.name") 
                .ellipsis.pt-2.w-100  
                    span.w-100  {{ item.name }}
          template(v-slot:item.count.contact_count='{ item }')
            .ellipsis
              span(v-if='$i18n.locale == "zh_TW" || $i18n.locale == "zh_CN"') {{ item.count.contact_count + " 人" }}
              span(v-else) {{ item.count.contact_count }}
          template(v-slot:item.count.campaign_count='{ item }')
            .ellipsis
              span(v-if='$i18n.locale == "zh_TW"') {{ item.count.campaign_count + " 個" }}
              span(v-if='$i18n.locale == "zh_CN"') {{ item.count.campaign_count + " 个" }}
              span(v-else) {{ item.count.campaign_count }}
          template(v-slot:item.owner_name='{ item }')
            .d-flex.justify-start.align-center
                v-list-item-avatar.ma-0.mx-2.d-none.d-lg-inline-block(color='aliceBlue' size='40')
                    v-icon(v-if="item.owner == null") mdi-account
                    v-icon(v-else-if="item.owner.usersetting.photo == null") mdi-account
                    img(v-else :src='item.owner.usersetting.photo')
                v-btn.w-75.d-inline-block(v-if="item.owner != null" :href='onMail(item.owner.email)' color='primary' text :title="item.owner_name") 
                    .ellipsis.w-100.pt-2
                        span.w-100  {{ item.owner_name }}
          template(v-slot:item.action='{ item }')
            v-btn(icon='icon' @click='onEdit(item)')
              v-icon(size='18') icon-edit
            v-btn(icon='icon' @click='onDelete(item.id)')
              v-icon(size='18') icon-remove
        label.text-right.mr-4 {{(page-1)*itemPerPage+1}} － {{currentData}} of {{pageDataCount}} 
        v-pagination.py-1.mb-3(v-model='page' :length='pageCount' circle :total-visible="10") 		
    v-dialog(v-model='messageDialog' width='360' content-class='statusDialog' @keydown.esc='onEmitMessageDialog(false)' @keydown.enter='onEmitMessageDialog(true)')
      message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message')
    v-dialog(v-model='successDialog' width='360' content-class='statusDialog' @keydown.enter='onEmitSuccessDialog(true)')
      success-dialog(@emitSuccessDialog='onEmitSuccessDialog')
    v-dialog(v-model='errorDialog' width='360' content-class='statusDialog' @keydown.esc='onEmitErrorDialog(false)' @keydown.enter='onEmitErrorDialog(true)')
      error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
    v-dialog(v-model='addSegDialog' max-width='500' v-if='addSegDialog')
      add-seg(@emitAddSeg='onEmitAddSeg',@emitAddSegData='addSegData' :addSegment='addSegment')
    v-dialog(v-model='editSegDialog' max-width='500' v-if='editSegDialog')
      edit-seg(@emitEditSeg='onEmitEditSeg', :segData='editSegData')

    
</template>

<script>
import loadingOverlay from "@/components/Common/loadingOverlay";
import addStaticSeg from "@/components/Dialog/Contact/addStaticSegment";
import messageDialog from "@/components/Dialog/messageDialog";
import successDialog from "@/components/Dialog/successDialog";
import errorDialog from "@/components/Dialog/errorDialog";
import addSeg from '@/components/Dialog/Segment/addSegment';
import editSeg from '@/components/Dialog/Segment/editSegment';
import SegmentDataService from "../services/SegmentDataService";
import segment_typeList from "@/array/the_segmentTypeList";
import i18n from '/common/plugins/vue-i18n.js' 


export default {
  components: {
    addSeg,
    editSeg,
    loadingOverlay,
    addStaticSeg,
    messageDialog,
    successDialog,
    errorDialog,
    SegmentDataService
  },
  data() {
    return {
      typeList: segment_typeList,
      overlay_loading: true,
      // table setting
      awaitingSearch: false, 
      sortBy:[],
      sortByForSearch:[],
      sortByDesc:[],
      page: 1,
      pageCount: 0,
      currentData:0,
      itemPerPage: this.getItemPerPage(),
      pageDataCount:null,
      selectedRows: [],
      isLoading: true,
      // Contact Table Data
      editSegData:{},
      addSegment:{},
      searchSegment: "",
      segmentsHeader: [
        {
          value: "data-table-select",
          align: "center",
          width: '100px',
        },
        {
          text: i18n.t('SEGMENT.NAME'),
          value: "name",
          align: "center",
          width: '200px',
        },
        {
          text: i18n.t('SEGMENT.TYPE'),
          value: "typeName",
          align: "center",
          width: '200px',
        },
        {
          text: i18n.t('SEGMENT.PEOPLE'),
          value: "count.contact_count",
          align: "right",
          width: '200px',
        },
        {
          text: i18n.t('SEGMENT.CAMPAIGNS'),
          value: "count.campaign_count",
          align: "right",
          width: '200px',
        },
        {
          text: i18n.t('SEGMENT.OWNER'),
          value: "owner_name",
          align: "center",
          width: '200px',
        },
        {
          text: i18n.t('SEGMENT.ACTIVE'),
          value: "action",
          align: "center",
          sortable: false,
          width: '200px',
        },
      ],
      contactData: [],
      addContactData: {},
      editContactData: {},
      originData:{},
      deleteContactIds: '',
      segmentList: [],
      // Dialog
      messageDialog: false,
      message: "",
      successDialog: false,
      successMessage: "",
      errorDialog: false,
      errorMessage: "",
      addSegDialog: false,
      editSegDialog: false,
      deleteId:"",
      delError:false,
      typeName:''
    };
  },
  async created() {
		this.getListData()
	},
	destroyed() {
		window.removeEventListener('resize', this.onResize)
	},
	methods: {
    async getListData(){
      this.segmentList = []
      await (this.isLoading = true);
      await SegmentDataService.getAll(this.page,this.searchSegment,this.itemPerPage,this.sortByForSearch).then((response)=> {
        this.segmentList = response.data.list;   
        this.pageCount = response.data.total_page
        this.pageDataCount = response.data.pageDataCount  
        this.currentData = (this.page)*this.itemPerPage >= this.pageDataCount ? this.pageDataCount:(this.page)*this.itemPerPage 
        // this.typeName = this.replaceType(this.segment.type);      
        this.segmentList.forEach(data => {      
          data.typeName = this.replaceType(data.type);   
          if (data.owner.first_name == null) {
            data.owner_name = data.owner.last_name;
          } 
          else {
            data.owner_name = data.owner.last_name +" "+ data.owner.first_name;
          }
        });
        // this.sortByForSearch = []
      })
      window.addEventListener('resize', this.onResize)
      this.onResize();
      await (this.isLoading = false);
    },
    getItemPerPage(){
        let windowH = window.innerHeight - 150; //table高度
        let objH = 70;
        return parseInt(windowH/objH);
    },
    replaceType(key) {
                let index = this.typeList.findIndex(item => {return item.key == key});
                return this.typeList[index].name;
    },
    onResize: function () {
      let windowH = window.innerHeight - 150; //table高度
      let objH = 70;
      this.itemPerPage = parseInt(windowH/objH);
    },
    onCall: function (cell) {
      return "tel:" + cell;
    },
    onMail: function (mail) {
      return "mailto:" + mail;
    },
    onGoogleMap: function (address) {
      return "https://www.google.com.tw/maps/place/" + address;
    },
    // Dialog Area ----------------------------------------- [Start]
    showAddStaticSeg(){
      this.addStaticSegDialog = true;
    },
    // Message Dialog
    async onDelete(id) {      
      if(id != null){
        this.showMessageDialog(i18n.t('SEGMENT.MESSAGE'));
        this.deleteId += id;
      }
      else{
        this.showMessageDialog(i18n.t('SEGMENT.MESSAGE'));
        for(let i = 0; i < this.selectedRows.length; i++)
        {
          this.deleteId += `${this.selectedRows[i].id},`;
        }        
        this.deleteId = this.deleteId.slice(0,-1)
      }      
    },
    showSuccessDialog() {
      this.successDialog = true;
    },
    onEmitSuccessDialog() {
      this.successDialog = false;
    },
    showErrorDialog(message) {
      this.errorDialog = true;
      this.errorMessage = message;
    },
    onEmitErrorDialog() {
      this.errorDialog = false;
    },
    showMessageDialog(message) {
      this.messageDialog = true;
      this.message = message;
    },
    onEmitMessageDialog(val) {
      this.messageDialog = false;
      if (val) {
        if(this.deleteId != ""){
          SegmentDataService.delete(this.deleteId)      
          .then((response) => {
                if(response.data.error === 'Have Campaign!'){
                      this.delError = true;                  
                      if(response.data.errorList != null){
                        this.showErrorDialog(i18n.t('SEGMENT.MESSAGE_2_1')+`${response.data.errorList}`+i18n.t('SEGMENT.MESSAGE_2_2'));
                      }
                      else{
                        this.showErrorDialog(i18n.t('SEGMENT.MESSAGE_3'));
                      }
                  }
                else if (response.status === 204 || response.status === 200) {
                  let IdArr = this.deleteId.split(',');
                  for(let i = 0; i < IdArr.length; i++){
                    let deleteItemMethod = (item) => item.id === IdArr[i];
                    let deleteItemIdx = this.segmentList.findIndex(deleteItemMethod);
                    this.segmentList.splice(deleteItemIdx, 1);
                    this.currentData = this.currentData - 1
                    this.pageDataCount = this.pageDataCount - 1
                  }
                  this.showSuccessDialog();
                }else {
                  this.showMessageDialog(
                    `${response.statusText}, ${response.status}`
                  );
                }                
                this.selectedRows = [];
                this.deleteId ='';                       
                this.delError = false; 
              })          
          }
      }else
      {
        if(this.deleteId != "")
          {
            this.deleteId =''; 
          }
      }
    },
    showAddSegDialog(copyData){
      if(copyData == null){        
        this.addSegment={
        name: '',
        type: "STATIC",
        description: ''
        }
      }else
      {this.addSegment = copyData}
      this.addSegDialog = true;
    },
    onEmitAddSeg(val){      
      if(val){
        this.addSegDialog = false;
        this.showSuccessDialog()
        this.getListData()
      }
      else{
        this.addSegDialog = false;
      }
    },
    async addSegData(data) {      
      if (data.owner.first_name == null) {
          data.owner_name = data.owner.last_name;
        } 
        else {
          data.owner_name = data.owner.last_name +" "+ data.owner.first_name;
        }    
      data.typeName = this.replaceType(data.type);    
      await this.segmentList.unshift(data)
      this.selectedRows = []
    },
    onEdit(data){
      this.originData = JSON.parse(JSON.stringify(data));
      this.editSegDialog = true;
      this.editSegData = data
    },
    onEmitEditSeg(val){
      if(val){
        this.editSegDialog = false;        
      }
      else{
        this.editSegDialog = false;
        let index = this.segmentList.findIndex((item) => item.id == this.originData.id);
        this.segmentList.splice(index, 1, this.originData);        
      }
    },
    onCopy(copyData){      
      this.showAddSegDialog(copyData)
    },
    // Dialog Area ----------------------------------------- [End]
  },
  watch:{
    "page":async function () {      
      await this.getListData()  
    },
    "searchSegment":async function () {      
      if (!this.awaitingSearch) {
				setTimeout(() => {
          this.page = 1
					this.getListData();
					this.awaitingSearch = false;					
				}, 1000);
				}
				this.awaitingSearch = true;  
    },     
    "sortByDesc":async function () {    
      this.sortByForSearch = JSON.parse(JSON.stringify(this.sortBy))
      for(let i in this.sortByDesc)
      {
        if(this.sortByDesc[i] == true )
        {
          this.sortByForSearch[i] = '-'+this.sortByForSearch[i]
        }
      }
      await this.getListData()  
    },
  }
};
</script>