<template lang="pug">
#LoginPage
	loadingOverlay(v-show='loading' message='Loading . . .' :zIndex='999')
	header(v-show='!loading')
	#login
		.container
			.row.justify-content-center
				.col-6.col-md-6
					.login-box.my-5
						h3.mb-4 {{$t('PASSWORDANDLOGIN.TITLE_2')}}
						form
							//- .input-group.mb-4
							//- 	input.form-control(v-model='tenant' type='text')
							h6.text-left.mb-4 {{$t('LOGIN.TENANT')}}: {{tenant}}
							p
									label {{$t('PASSWORDANDLOGIN.NEW_PASSWORD')}}
									v-text-field(
											v-model='password.new' type='password' solo dense required  @blur='checkLength'
									)
									span(v-show='passwordCheck!=null&&passwordCheck===false' style='color:#ff0517').mt-2.lighten-2--text.text-caption {{$t('PASSWORDANDLOGIN.ERROR')}}
							p
									label {{$t('PASSWORDANDLOGIN.NEW_PASSWORD_2')}}
									v-text-field(
											v-model='password.confirm' type='password' solo dense required @blur='checkConfirm'
									)
									span(v-show='confirmPasswordCheck!=null&&confirmPasswordCheck===false' style='color:#ff0517').mt-2.lighten-2--text.text-caption {{$t('PASSWORDANDLOGIN.ERROR_2')}}
							//- .input-group.mb-4
							//- 	input.form-control(v-model='password.new' type='password' placeholder='Password')
							//- .input-group.mb-4
							//- 	input.form-control(v-model='password.confirm' type='password' placeholder='Confirm Password')
							input.btn.hero-btn.w-100(type="submit", :value=`$t('GENERAL.CONFIRM')` @click='onConfirmPassword($event)')
	v-dialog(v-model='successDialog' width='360' content-class='statusDialog' @keydown.enter='onEmitSuccessDialog(true)')
		success-dialog(@emitSuccessDialog='onEmitSuccessDialog')
	v-dialog(v-model='errorDialog' width='360' content-class='statusDialog' @keydown.enter='onEmitErrorDialog(true)')
		error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
</template>
<script>
import successDialog from "@/components/Dialog/successDialog";
import errorDialog from "@/components/Dialog/errorDialog";
import loadingOverlay from "@/components/Common/loadingOverlay";
import AccountDataService from "../services/AccountDataService";
import i18n from '/common/plugins/vue-i18n.js' 

export default {
  name: "Login",
  components: {
		errorDialog,
		successDialog,
		loadingOverlay
  },
  data() {
	return {
		loading: false,
		password: {
			new: '',
			confirm: '',
		},
		token: this.$route.query.token,
		tenant: this.$route.query.tenant,
		successDialog: false,
		errorDialog: false,
		errorMessage: '',
		passwordCheck:null,
    confirmPasswordCheck:null,
		nextPath: '/',
		goloagin:false,
	};
  },
	created(){
			let user = JSON.parse(window.localStorage.getItem('user'));
			if(user != null)
			{
					this.changePassword = user.change_password
					if(this.changePassword)
					{
							this.$router.replace('/Setting#PasswordAndLogin')
					}
					else{
							this.$router.replace(this.nextPath);
					}
			}
	},
  methods: {
		checkLength(){
                const regex = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&]{8,30}/);
                this.passwordCheck = regex.test(this.password.new)
            },
		checkConfirm(){
				if(this.passwordCheck===true&&this.password.confirm===this.password.new)
				{
						this.confirmPasswordCheck = true
				}
				else{
						this.confirmPasswordCheck = false
						}
		},
	async onConfirmPassword(event) {
		event.preventDefault();
		if (!this.password.new || !this.password.confirm)
		{
			this.errorDialog = true;
			this.errorMessage = i18n.t('PASSWORDANDLOGIN.ERROR_4');
			return false;
		}
		if (this.password.new != this.password.confirm)
		{
			this.errorDialog = true;
			this.errorMessage = i18n.t('PASSWORDANDLOGIN.ERROR_7');
			return false;
		}
		var data = { 'tenant': this.tenant, 'token': this.token, 'password': this.password.new };
		this.loading = true;
		await AccountDataService.confirmPassword(data)
		.then(() => {
			this.successDialog = true;
		})
		.catch(response =>{
			if(response == 'Error: Request failed with status code 404')
			{
				this.errorDialog = true;
				this.errorMessage = i18n.t('PASSWORDANDLOGIN.ERROR_9');
				this.goloagin = true
			}else{
			this.errorDialog = true;
			this.errorMessage = i18n.t('PASSWORDANDLOGIN.ERROR_8') + `${response.response.data.password[0]}`;
			}
		});
		this.loading = false;
	},
	onEmitSuccessDialog() {
		this.successDialog = false;
		this.$router.replace('/login');
	},
	onEmitErrorDialog() {
		if(this.goloagin){
			this.$router.replace('/login');
		}
		this.errorDialog = false;
	},
  },
	watch:{
			"password.new":function(e){
					const regex = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&]{8,30}/);
					this.passwordCheck = regex.test(e)
			},
			"password.confirm":function(e){
					if(this.passwordCheck===true&&e===this.password.new){
							this.confirmPasswordCheck = true                    
					}else
					{
							this.confirmPasswordCheck = false
					}                
			},
	}
};
</script>

<style scoped src="../../static/loginPage/plugins.css"></style>
<style scoped src="../../static/loginPage/common.css"></style>
<style scoped src="../../static/loginPage/login.css"></style>
