<template lang="pug">
#UpChat(v-if='this.chat_url')
    loadingOverlay(v-show='!loading || !contact_loading' message='Loading . . .' :zIndex='999')
    //- v-btn(@click='testAdd()' style='position:absolute; top:0; right:0 ;')
    v-btn(icon elevation='' :ripple='false' :class=' this.open ? "collapse_btn": "collapse_btn_open"' @click='collapseFrame' v-if='loading') 
        v-icon(size='30' v-if='open') mdi-menu-right
        v-icon(size='30' v-else) mdi-menu-left
    iframe(id="crm_frame_1" class="crm_frame" title="CRM" src="/CRM" v-show='loading')
    iframe(id="chat_frame" class="chat_frame" title="UpChat" :src="chat_url" v-show='loading' allow="clipboard-read; clipboard-write")
</template>

<script>
import Vue from "vue";
import PreferenceService from "../services/PreferenceService";
// import i18n from '/common/plugins/vue-i18n.js'
import loadingOverlay from "@/components/Common/loadingOverlay";
import UpChatDataService from '../services/UpChatDataService';

export default Vue.extend({
    components: {
        loadingOverlay
    },
    data() {
        return {
            chat_url:null,
            loading:false,
            open: true,
            contact_loading:true,
            hasUpChatLogin: false,
        };
    },
    async created() {
        if (window.localStorage.getItem('upchat_login') != null)
            this.hasUpchatLogin = window.localStorage.getItem('upchat_login') === 'true' ? true : false;
        document.body.classList.add('chat_hide_scroll_bar');
        await PreferenceService.getUpChat()
        .then((response)=>{
            try{
                if(response.data.upchat == true){
                    this.chat_url = response.data.host;
                }
            }
            catch(e){
                console.log(e)
            }
        })
        await this.addListener();
        await this.addWsListener();
        await this.getLoginUrl();
        await (this.loading = true)
    },
    methods: {
        collapseFrame(){
            const chatFrame = document.getElementById('chat_frame');
            const crmFrame = document.getElementById('crm_frame_1');
            const v_main = crmFrame.contentWindow.document.getElementsByClassName('v-main')[0];
            const menuBar = crmFrame.contentWindow.document.getElementsByClassName('v-application--wrap')[0];
            
            chatFrame.classList.toggle('chat_frame_collapse');
            crmFrame.classList.toggle('crm_frame_collapse');
            v_main.classList.toggle('v_main_collapse');
            if(!menuBar.classList.contains('menuBar_collapse')){
                menuBar.classList.add('menuBar_collapse');
            }
            this.open ? this.open = false : this.open = true;
        },
        addListener(){
            window.addEventListener("message", (event) => {
                // origin is where iframe src is, so chat.upday.ai?  or something else depends on where the src is
                // can ignore this condition as we only care about the same windows
                // if (event.origin !== "http://localhost:8080") {
                //     console.log("event.origin is "+event.origin);
                //     return;
                // }
                if(event.data == "fin_contact_loading"){
                    this.contact_loading = true;
                }
                if (event.origin != this.chat_url) {
                    // console.log("event.origin is "+event.origin);
                    return;
                }

                //alert("Main receive event data: "+event.data);
                /* FRAME_CRM */
                if (event.origin == this.chat_url) {
                    if (event.data == 'chatlist-loaded') {
                        let chatFrame = document.getElementById("chat_frame");
                        chatFrame.contentWindow.postMessage("chatui-update", '*');
                    } else {
                        let e = document.getElementById("crm_frame_1");
                        e.contentWindow.postMessage(event.data, '*');
                        this.contact_loading = false;
                    }
                }
                /* Get event.data - contact email, then use email to search and get contact detail URL
                    and set iframe src
                var frameEle = document.getElementById("crm_frame_1");
                frameEle.setAttribute("src", "https://sandbox.upday.ai/Contacts/5b326889-7b95-4be7-9467-0d5fda75b93e");
                */
            }, false)
        },
        testAdd(){
            const eventData = 
                { 
                    event: 'appContext',
                    contact: 
                    {
                        "additional_attributes":
                        {
                            "description":"",
                            "company_name":"",
                            "social_profiles":
                            {
                                "github":"",
                                "twitter":"",
                                "facebook":"",
                                "linkedin":""
                            }
                        },
                        "email":"",
                        "id":4,
                        "name":"NEW0",
                        "phone_number":"",
                        "identifier":null,
                        "thumbnail":"",
                        "custom_attributes":{},
                        "last_activity_at":1661982471
                    }
                };
            let e = document.getElementById("crm_frame_1");
            e.contentWindow.postMessage(JSON.stringify(eventData), '*');
            this.contact_loading = false;
        },
        getLoginUrl() {
            let user = window.localStorage.getItem('user');
            user = JSON.parse(user);
            let upchatIframe = document.querySelector('#chat_frame')
            if (!this.hasUpchatLogin) {
                upchatIframe.src = user.upchat_sso_link;
                window.localStorage.setItem('upchat_login', true);
                // UpChatDataService.loginUpchat(user['userid'])
                // .then(response =>{
                //     if(response.status == 200) {
                //         upchatIframe.src = response.data;
                //     }
                // })
                // .catch((response) => {
                //     console.log(response.response.data)
                // })
            } else {
                upchatIframe.src = user['upchat_host'];
            }
        },
        addWsListener() {
            UpChatDataService.getWsInfo()
            .then(response =>{
                if(response.status == 200) {
                    const pubSubToken = response.data.pub_sub_token;  // this pubsub token can be obtained from <chatwoot host>/profile API
                    const wsAccountId = response.data.ws_account_id;  // account of websocket subscription event
                    const ws = new WebSocket(response.data.ws_url);

                    ws.onopen = function() {
                        var wsId = { 
                                        channel: "RoomChannel",
                                        pubsub_token: pubSubToken,
                                        account_id: wsAccountId
                                };
                        var wsInfo = { command:"subscribe", identifier: JSON.stringify(wsId) };
                        ws.send(JSON.stringify(wsInfo));
                    };

                    ws.onerror = function (error) {
                        // just in there were some problems with connection...
                        console.log('There is some problem with your connection or the server is down. '+error);
                    };

                    ws.onmessage = function(message) {
                        try {
                            var json = JSON.parse(message.data);
                        } catch(e) {
                            console.log('this does not look like a valid JSON: ', message.data);
                            return;
                        }
                        if (json.message != undefined && json.message.event === 'contact.updated') {
                            let contact = json.message.data;
                            contact['now'] = new Date().toISOString();
                            // Only call API if same account
                            if (wsAccountId == contact.account_id) {
                                //console.log(`Same account ${contact.account_id}, contact "${contact.name}" updated.`);
                                // call CRM API to update contact
                                UpChatDataService.updateContact(contact);
                            } else {
                                console.log(`Different account ${contact.account_id}, no action.`);
                            }
                        } else {
                            // ignore
                        }
                    };
                }
            })
            .catch((response) => {
                console.log(response)
            })
        }
    },
});
</script>

<style lang="scss">
body {
    margin: 0px;
}
iframe {
    height: 100vh;
    border: 0px;
}

.crm_frame {
    width: 60%;
}

.crm_frame_collapse{
    transition: .1s;
    width: 100%;
}

.chat_frame {
    background-color: white;
    transition: .1s;
    width: 40%;
}

.chat_frame_collapse{
    width: 0;
}

.v_main_collapse{
    transition: .2s;
    margin: 0 !important;
}

.menuBar_collapse{
    flex-direction: inherit;
}

.collapse_btn{
    padding: 0 0;
    background-color: rgb(255, 255, 255);
    position: absolute;
    top: 0;
    right: 40%;
    border-radius: 50% 0 0 50%;
    
}
.collapse_btn_open{
    padding: 0 0;
    background-color: rgb(255, 255, 255);
    position: absolute;
    top: 0;
    right: 0%;
    border-radius: 50% 0 0 50%;
    
}
</style>