<template lang="pug">
  div(v-if='isAdmin')
    v-row.pt-2
      v-col(cols='10')
        h1 Upgpt Basic Setting
      v-col.d-flex.align-end.justify-end(cols='1')
        v-btn(@click="reloadChroma_db" height="40" color="green" depressed dark)
          span {{$t('GENERAL.RELOAD')}}
      v-col.d-flex.align-end.justify-end(cols='1')
        v-btn(@click="onSavePrompt" height="40" color="green" depressed dark)
          span {{$t('GENERAL.SAVE')}}
    v-form(ref='prompt')
      v-row
        v-col(cols='6')
          v-textarea.textarea-style.bg-white.mt-3(v-model='promptSettingData.summary_prompt' rows="10" label=`Summary Prompt` ,prepend-inner-icon="mdi-equal-box"  outlined)
        v-col(cols='6')
          v-textarea.textarea-style.bg-white.mt-3(v-model='promptSettingData.system_prompt' rows="10" label=`System Prompt` ,prepend-inner-icon="mdi-equal-box"  outlined)
    v-row.pt-3
      v-col(cols='11')
        h1 Preferences
      v-col.d-flex.align-end.justify-end(cols='1')
        v-btn(@click="showAddDialog" height="40" color="green" depressed dark)
          v-icon.mr-1(size="14") icon-add
          span {{$t('PREFERENCES.ADD_PREFERENCE')}}
    .px-4.py-3
      section
        v-data-table.dashboard-table(
          :headers="preferenceHeader",
          :items="preferenceList",
          :items-per-page="itemPerPage",
          :page.sync="page",
          @page-count="pageCount = $event",
          fixed-header=true,
          hide-default-footer="hide-default-footer",
          dense
        )
          template( v-slot:item.tenant="{ item }" )
            .ellipsis.pl-3
              span(:title='item.tenant') {{item.tenant}}
          template( v-slot:item.code="{ item }" )
            .ellipsis.pl-3
              span(:title='item.code') {{item.code}}
          template( v-slot:item.name="{ item }" )
            .ellipsis.pl-3
              span(:title='item.name') {{item.name}}
          template( v-slot:item.jsonValue="{ item }" )
            .ellipsis.pl-3
              span(:title='item.jsonValue') {{item.jsonValue}}
          template( v-slot:item.description="{ item }" )
            .ellipsis.pl-3
              span(:title='item.description') {{item.description}}
          template(v-slot:item.action="{ item }")
            v-btn(icon @click="showEditDialog( item )")
              v-icon(size="18" ) icon-edit
            v-btn(icon @click="onDelete( item )")
              v-icon(size="18") icon-remove
        v-pagination.py-1.mb-3(v-model='page' :length='pageCount' v-if='pageCount > 1' circle :total-visible="10") 
      
      v-dialog(v-model="AddOrEditDialog" max-width="500" content-class="setting-dialog" v-if="AddOrEditDialog")
        v-card
            v-card-title.d-flex.justify-center {{addOrEdit}} {{$t('PREFERENCES.PREFERENCE')}}
            v-card-text
              v-form(v-model="addOrEditData.valid", ref="form")
                v-text-field.no-border.v-text-field__slot(
                  v-model="addOrEditData.code",
                  type="text",
                  :label=`$t('PREFERENCES.CODE')`,
                  prepend-icon="mdi-mail",
                  :rules="formRule.codeRule"
                )
                v-text-field.no-border.v-text-field__slot(
                  v-model="addOrEditData.name",
                  type="text",
                  :label=`$t('PREFERENCES.NAME')`,
                  prepend-icon="mdi-mail",
                  :rules="formRule.nameRule"
                )
                v-textarea.textarea-style.no-border.v-text-field__slot(
                  v-model="addOrEditData.jsonValue",
                  type="text",
                  :label=`$t('PREFERENCES.VALUE')`,
                  prepend-icon="mdi-equal-box",
                  no-resize
                  :rules="formRule.requiredField"
                )
                v-textarea.textarea-style.no-border.v-text-field__slot(
                  v-model="addOrEditData.description",
                  type="text",
                  :label=`$t('PREFERENCES.DESCRIPTION')`,
                  prepend-icon="mdi-equal-box",
                  no-resize
                )
              v-card-actions.mt-8.px-0.py-0
                v-row(no-gutters)
                  v-col.pr-2(cols="6")
                    v-btn(
                      :ripple="false",
                      @click="onCancel",
                      width="100%",
                      height="40",
                      color="cancel",
                      depressed,
                      dark
                    ) {{$t('GENERAL.CANCEL')}}
                  v-col.pr-2(cols="6")
                    v-btn(
                      :ripple="false",
                      @click="onAddOrEdit(addOrEditData)",
                      width="100%",
                      height="40",
                      color="success",
                      depressed,
                      dark
                    ) {{addOrEditButton}}
      v-dialog(v-model='messageDialog' width='360' content-class='statusDialog')
          message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message')
      v-dialog(v-model='successDialog' width='360' content-class='statusDialog' @keydown.enter='onEmitSuccessDialog(true)')
          success-dialog(@emitSuccessDialog='onEmitSuccessDialog')
      v-dialog(v-model='errorDialog' width='360' content-class='statusDialog')
          error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
</template>

<script>
  import Vue from "vue";
  import PreferenceService from "@/services/PreferenceService";
  import messageDialog from "@/components/Dialog/messageDialog";
  import successDialog from "@/components/Dialog/successDialog";
  import errorDialog from "@/components/Dialog/errorDialog";
  import loadingOverlay from "@/components/Common/loadingOverlay";
  import i18n from '/common/plugins/vue-i18n.js';
  import UpGPTService from "@/services/UpGPTDataService";
  
  export default Vue.extend({
    components: {
    loadingOverlay, 
    messageDialog,
    successDialog,
    errorDialog,
  },
  data() {
    return {
      //- label
      addOrEdit: i18n.t('GENERAL.ADD'),
      addOrEditButton: i18n.t('GENERAL.ADD'),
      //- dialog
      successDialog: false,    
      errorDialog: false,
      messageDialog: false,
      AddOrEditDialog: false,
      message: '',
      errorMessage: '',
      //- table
      preferenceHeader: [
        {
          text: i18n.t('PREFERENCES.TENANT'),
          value: "tenant",
          align: "left",
          width: '10%',
          class: "blue lighten-5",
          sortable: false
        },
        {
          text: i18n.t('PREFERENCES.CODE'),
          value: "code",
          align: "left",
          width: '15%',
          class: "blue lighten-5",
          sortable: false
        },
        {
          text: i18n.t('PREFERENCES.NAME'), 
          value: "name",
          align: "left",
          width: '15%',
          class: "blue lighten-5",
          sortable: false
        },
        {
          text: i18n.t('PREFERENCES.VALUE'), 
          value: "jsonValue",
          align: "left",
          width: '30%',
          class: "blue lighten-5",
          sortable: false
        },
        {
          text: i18n.t('PREFERENCES.DESCRIPTION'),
          value: "description",
          align: "left",
          width: '25%',
          class: "blue lighten-5",
          sortable: false
        },
        {
          text: i18n.t('PREFERENCES.ACTIVE'),
          value: "action",
          align: "center",
          width: '15%',
          class: "blue lighten-5",
          sortable: false
        },
      ],
      page: 1,
      pageCount: 1,
      itemPerPage: 10,
      //- data
      addOrEditData: this.initializeAddOrEditData(),
      preferenceList:[],
      tenant:null,
      delId: null,
      isAdmin:true, //是否顯示頁面取決於是否為updayAdmin
      //- form rule
      formRule: {
        requiredField:[(v) => !!v || i18n.t('RULE.RULE_R')],
        codeRule:[
          (v) => !!v || i18n.t('RULE.RULE_R'),
          (v) => v.length <= 30 || i18n.t('RULE.RULE_30'),
          (v) => /^[A-Z_]*$/.test(v) || i18n.t('PREFERENCES.RULE_UPPERCASE')],
        nameRule:[
          (v) => !!v || i18n.t('RULE.RULE_R'),
          (v) => v.length <= 100 || i18n.t('RULE.RULE_100')],
      },
      promptSettingData:{
        "summary_prompt":"",
        "system_prompt":""
      },
    }
  },
  created() {
    this.tenant = window.localStorage.getItem("tenant");
    PreferenceService.preferenceList()
    .then(response=>{
      response.data.forEach(element => {
        element.tenant = this.tenant;
        element.jsonValue = element.value
        if(typeof(element.value) == "object") {
            element.jsonValue = JSON.stringify(element.value)
        }
      });
      this.preferenceList = response.data
    }).catch(()=>{
      // 非upday admin
      this.isAdmin = false
    })

    UpGPTService.getPromptSettingData().then(response => 
    {
        this.promptSettingData = response.data;
    })
    .catch((response) => {
        this.showErrorDialog(response);
    });
  },
  methods: {
    validateForm() {
      if (this.$refs.form.validate()) {
        return true;
      } else {
        return false;
      }
    },
    initializeAddOrEditData() {
      return {
        code: "",
        name: "",
        value:"",
        description:""
      };
    },
    // 打開新增窗格
    showAddDialog() {
      this.AddOrEditDialog = true;
      this.addOrEdit = i18n.t('GENERAL.ADD');
      this.addOrEditButton = i18n.t('GENERAL.ADD');
      this.addOrEditData = this.initializeAddOrEditData();
    },
    // 打開編輯窗格
    showEditDialog(data){
      this.AddOrEditDialog = true;
      this.addOrEdit = i18n.t('GENERAL.EDIT');
      this.addOrEditButton = i18n.t('GENERAL.SAVE');
      this.addOrEditData = JSON.parse(JSON.stringify(data));
    },
    // 關閉新增或編輯窗格
      onCancel() {
      this.AddOrEditDialog = false;
    },
    // 判斷為儲存動作還是編輯動作並驗證表單
    async onAddOrEdit(data) {
      if(this.validateForm())
      {
        // 判斷code是否重複
        if(this.preferenceList.find(n => n.code == data.code && n.id != data.id))
        {
          this.showErrorDialog(i18n.t('PREFERENCES.ERROR'));
          return;
        }
        // 判斷value是否為JSON格式
        try {
          data.value = JSON.parse(data.jsonValue)
        }
        catch {
          this.showErrorDialog(data.jsonValue + i18n.t('PREFERENCES.ERROR1'))
          return
        }
        //Edit
        if(data.id) {
          await this.OnSave(data);
        }
        //Add
        else {
          await this.onAdd(data);
        }
        this.addOrEditData = this.initializeAddOrEditData()
      }
    },
    // 新增動作
    async onAdd(data) {
      await PreferenceService.createPreference(data)
        .then(response => {
          if(response.data.id){
            response.data.tenant = this.tenant;
            response.data.jsonValue = JSON.stringify(response.data.value);
            this.preferenceList.push(response.data);
            this.showSuccessDialog();
          }
        })
      this.AddOrEditDialog = false;
    },
    // 編輯動作
    async OnSave(data) {
      await PreferenceService.editPreference(data)
        .then(response=>{
          if(response.data.id) {
            response.data.tenant = this.tenant;
            response.data.jsonValue = JSON.stringify(response.data.value);
            let editIndex = this.preferenceList.findIndex(p => p.id == this.addOrEditData.id);
            this.$set(this.preferenceList,editIndex,response.data);
            this.showSuccessDialog();
          }
        })
      this.AddOrEditDialog = false;
    },
    // 確認刪除
    onDelete(item) {
      this.showMessageDialog(i18n.t('PREFERENCES.MESSAGE'));
      this.delId = item.id;
    },
    // 刪除動作
    onEmitMessageDialog(val){
      this.messageDialog = false;
      if (val) {
        PreferenceService.deletePreference(this.delId)
        .then(() => {
          let delIndex = this.preferenceList.findIndex(p => p.id == this.delId);
          this.preferenceList.splice(delIndex, 1);
          this.delId = null;
          this.showSuccessDialog();
        }).catch(response => {
          this.showErrorDialog(response.data);
        });
      }
    },
    //delete、error、success Dialog
    showMessageDialog(message) {
        this.messageDialog = true;
        this.message = message;
    },
    showSuccessDialog() {
        this.successDialog = true;
    },
    showErrorDialog(message) {
        this.errorDialog = true;      
        this.errorMessage = message;
    },
    onEmitErrorDialog() {
        this.errorDialog = false;
    },
    onEmitSuccessDialog() {
        this.successDialog = false;
    },
    onSavePrompt()
    {
        if(this.$refs.prompt.validate()){
            UpGPTService.setPromptSettingData(this.promptSettingData).then((response)=>
            {
                this.promptSettingData = response.data;
                this.showSuccessDialog();
            })
        }
    },
    reloadChroma_db() {
      UpGPTService.reloadChroma_db().then(()=>{
        this.showSuccessDialog();
      }).catch((response) => {
        this.showErrorDialog(String(response));
      })
    },
  }
})
</script>
    